import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import MainApp from './core/containers/Main';
import { Routes, Route, BrowserRouter} from 'react-router-dom'

import store from 'Redux/store';
import packageJson from '../package.json';
import { LogManager } from 'Core/logManager';

const App = () => {

	//wait for charge the legacy form
	useEffect(() => {
		LogManager(`Version. ${packageJson.version}`);
	}, []);

	return (
		<>
			{/* Load global frame for legacy integration*/}
			<Provider store={store}>
				<BrowserRouter>
					<Routes>
						<Route path="*" element={<MainApp />} />
					</Routes>
				</BrowserRouter>
			</Provider>
		</>
	);
};


ReactDOM.createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
