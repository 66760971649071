//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientGraphQLAuthMutation } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { getLegacyUrl } from 'Core/data/Helpers';
import { v4 as uuidv4 } from 'uuid';

//import { clientGET } from 'Core/data/RestApi';

//action types
import {
	GET_USER_INFO,
	LOGOUT,
	REFRESH_TOKEN,
	GET_ACCESS_ELD_APOLLO,
	CHANGE_SALESFORCE_STATUS
} from 'Redux/actionTypes';

//actions
import {
	logout,
	getUserInfoResult,
	setIsDeveloper,
	clearRedux,
	refreshTokenResult,
	getSystemPrefResult,
	getAccessEldApolloResult,
	changeSalesForceStatusResult
} from 'Redux/actions';

import {
	setCookie,
	eraseCookie
} from 'Core/views/login/helper';

/* FUNCTIONS ASYNC */

function* getUserInfoRequest({ payload }) {
	const { navigate } = payload;
	try {
		let data = yield call(
			clientQuery,
			`{
					User: getLoginInformation {
						username
						subUserId
						companyId
						companyName
						dateformat
						durationFormat
						reportOptions
						siteOptions
						language
						dealerId
						dealerName
						timeZone
						isSubUser
						options
						packageId
						packageName
						createdOn
						unitsCount
						promilesData
						activeTab
						email
						fullName
						accountDataPump
						subuserDataPump
						isKph
						eldUser {
							username
							password
						}
					}
        }`,
			{},
			endpoints.GRAPHQL_GENERAL,
		);

		let dataPref = yield call(
			clientQuery,
			`query getPreference ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: String!) {
				preference: getPreference (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKey: $preferenceKey) {
					id
					projectKey
					entityName
					entityId
					preferenceKey
					preferenceValue
				}
			}`,
			{
				"projectKey": "PX",
				"entityName": "System",
				"entityId": "endPoints",
				"preferenceKey": "environments"
			},
			endpoints.GRAPHQL_GENERAL,
		);

		if (data && data.User) {

			let isDeveloper = !!((process.env.ENVIROMENT && process.env.ENVIROMENT == "development"));
			yield put(setIsDeveloper(isDeveloper));

			localStorage.setItem("username", data.User.username)
			sessionStorage.setItem("sessionId", uuidv4())

			yield put(getSystemPrefResult(dataPref?.preference && JSON.parse(dataPref?.preference.preferenceValue)))
			yield put(getUserInfoResult(data.User));

		}
		else
			throw 'not user';

	} catch (exc) {

		ExceptionManager(exc, 'Core/containers/Auth/redux/saga', 'getUserInfoRequest');
		yield put(logout(navigate));
		yield put(getUserInfoResult());

	}
}

function* watchGetUserInfoRequest() {
	yield takeEvery(GET_USER_INFO, getUserInfoRequest);
}

function* logoutAsync({ payload }) {
	const { navigate, current_reseller, type_reseller } = payload;
	// duplicate information in a new varible in order to not be clear for the clearRedux("") in the logout
	const newReseller = current_reseller && JSON.parse(JSON.stringify(current_reseller));

	const type = type_reseller && JSON.parse(JSON.stringify(type_reseller));
	try {
		yield put(clearRedux(""));
		localStorage.removeItem('AccessToken');
		localStorage.removeItem('RefreshToken');
		localStorage.removeItem('IdToken');
		localStorage.removeItem('username');
		localStorage.removeItem('sm');
		eraseCookie('GPSTSharedOAUTHToken')
		//localStorage.removeItem('legacyUrl');
		let keys = Object.keys(sessionStorage)
		keys.forEach(key => {
			sessionStorage.removeItem(key)
		})
		
		if (newReseller && type !== 'id') {
			// If the reseller was login by domain or id, we redirect to the normal logout
			if (type === 'key' && newReseller.key) {
				// reseller logout
				navigate(`/reseller/${newReseller.key}`);
			} else {
				// normal logout
				navigate('/login');
			}
		} else if (navigate) {
			const domainUri = window.location.hostname === "localhost" ? `http://localhost:40000` : `https://${window.location.hostname}`;
			window.location = `${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}logout?client_id=${endpoints.COGNITO_CLIENT_ID}&logout_uri=${domainUri}/login`
			//navigate('/logout-cognito');
		}
	} catch (exc) {
		// catch throw
		ExceptionManager(exc, 'Core/containers/Auth/redux/saga', 'logoutAsync');
		if (navigate)
			navigate('/login');
	}
}

function* watchLogout() {
	yield takeEvery(LOGOUT, logoutAsync);
}

function* refreshTokenRequest() {
	try {

		const refreshToken = localStorage.getItem('RefreshToken')
		if (!refreshToken) {
			yield put(refreshTokenResult(false));
			return
		}

		let data = yield call(
			clientGraphQLAuthMutation,
			`mutation refreshToken($refreshToken: String!, $username: String!) {
				result: refreshToken(refreshToken: $refreshToken, username: $username) {
					authenticationResult {
						AccessToken
						ExpiresIn
						IdToken
						RefreshToken
					}
				}
			}`,
			{
				refreshToken: refreshToken,
				username: localStorage.getItem("username")
			},
			endpoints.GRAPHQL_GENERAL,
		);

		if (data && data.result && data.result.authenticationResult) {
			const { authenticationResult } = data.result
			//	token = authenticationResult.IdToken;
			localStorage.setItem('IdToken', authenticationResult.IdToken);
			setCookie("GPSTSharedOAUTHToken", authenticationResult.IdToken, 1)
			localStorage.setItem('AccessToken', authenticationResult.AccessToken);
			authenticationResult.RefreshToken = localStorage.getItem("RefreshToken")

			yield put(refreshTokenResult(true));
		}
		else
			throw 'Error refresh token';

	} catch (exc) {

		ExceptionManager(exc, 'Core/containers/Auth/redux/saga', 'refreshTokenRequest');
		yield put(refreshTokenResult(false));

	}
}

function* watchRefreshToken() {
	yield takeEvery(REFRESH_TOKEN, refreshTokenRequest);
}

function* getAccessEldApollo({ payload }) {
	const { username, password } = payload
	try {

		let QUERY = `
            query getEldAccess ($eldUser: EldUserInput) {
                result: getEldAccess (eldUser: $eldUser) {
                    access
                }
            }
        `

		const response = yield call(
			clientQuery,
			QUERY,
			{
				eldUser: {
					username,
					password
				}
			},
			endpoints.GRAPHQL_GENERAL,
		);

		yield put(getAccessEldApolloResult(response?.result?.access))

	} catch (exc) {
		yield put(getAccessEldApolloResult(null))
		ExceptionManager(
			exc,
			"Core/containers/Auth/redux/saga",
			"getAccessEldApollo"
		);
	}
}

function* watchAccessEldApollo() {
	yield takeEvery(GET_ACCESS_ELD_APOLLO, getAccessEldApollo);
}

function* changeSalesForceStatus({ payload }) {
	try {
		const element = document.getElementsByClassName('showDockableContainer')
		if (!element[0]?.style) return
		if (!payload) {
			element[0].style.display = 'none';
		} else {
			element[0].style.display = 'flex';
		}

		yield put(changeSalesForceStatusResult(!payload))

	} catch (exc) {
		yield put(changeSalesForceStatusResult(payload))
		ExceptionManager(
			exc,
			"Core/containers/Auth/redux/saga",
			"changeSalesForceStatus"
		);
	}
}

function* watchChangeSalesForceStatus() {
	yield takeEvery(CHANGE_SALESFORCE_STATUS, changeSalesForceStatus);
}

//MERGE SAGA
export default function* rootSaga() {
	yield all([
		fork(watchGetUserInfoRequest),
		fork(watchLogout),
		fork(watchRefreshToken),
		fork(watchAccessEldApollo),
		fork(watchChangeSalesForceStatus),
	]);
}
