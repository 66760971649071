import React from 'react';

// Material UI Components
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SeverityOptions from 'Modules/alerts/common/components/SeverityOptions';

// Components
import { Select, Input, Checkbox } from 'Components';
import {openModalBehaviorAdvanceFilter} from "Modules/behavior/views/report/modals/filter/redux/slice";
const ReportCustomFields = ({
  reportType,
  messages,
  advancedFilter,
  otherFilters,
  setOtherFilters,
  selectEventTypeOptions,
  landmarkTags,
  getAllLandmarks,
  landmarkViewOptions,
  landmarks,
  landmarkTagsLoading,
  filter,
  getGeofencesTags,
  geofenceViewOptions,
  geofences,
  geofenceTagsLoading,
  driverViewOptions,
  forms,
  dynamicForms,
  serviceItems,
  services,
  eventTypeVideoOptions,
  optionsSelectReportType
}) => {
  return (
    <div style={{ width: "100%" }}>
      {['speedReport', 'speedCoachingReport'].includes(reportType?.id)  && (
        <>
          <Input
            id="speed"
            label={messages['reportsMainRT_speedFilter']}
            type="number"
            min={0}
            onChange={(value) => {
              let number = value.replace(/[.,,]/, '');
              if (+number >= 0) {
                setOtherFilters({ ...otherFilters, speed: number })
              }
            }}
            value={otherFilters.speed}
            requiredMessage={messages['required_field']}
            maxLength={5}
            placeholder={messages['reportsMainRT_enterValue']}
          />
          <Input
            id="speedLimit"
            label={messages['reportsMainRT_speedLimit']}
            type="number"
            min={0}
            onChange={(value) => {
              let number = value.replace(/[.,,]/, '');
              if (+number >= 0) {
                setOtherFilters({ ...otherFilters, speedLimitDelta: value })
              }
            }}
            value={otherFilters.speedLimitDelta}
            requiredMessage={messages['required_field']}
            maxLength={5}
            placeholder={messages['reportsMainRT_enterValue']}
          />
        </>
      )}
      {['stopReport', 'movingReport','formStopSummaryReport', 'formStopSummaryReportV2'].includes(reportType?.id) && (
        <>
          <Input
            id="minutes"
            label={`${reportType?.id === 'movingReport' ? 'Moving' : ''} ${messages['reportsMainRT_minutesFilter']}`}
            type="number"
            min={0}
            minMessage={`${messages['min']}: 0`}
            max={10}
            maxLength={5}
            onlyPositive={true}
            onChange={(value) => setOtherFilters({ ...otherFilters, minute: value })}
            value={otherFilters.minute}
            maxMessage={`${messages['max']}: 999999`}
            requiredMessage={messages['required_field']}
            placeholder={messages['reportsMainRT_enterValue']}
            className="report-minutes-filter"
          />
          {
            ['stopReport'].includes(reportType?.id)  && (
              <div className="report-checkbox-margin-top-20">
                <Checkbox
                  id={'id_productive_idling'}
                  value={otherFilters.excludeIdle}
                  label={messages['reportSetup_excludeIdleCheckbox']}
                  onChange={(value) => setOtherFilters({ ...otherFilters, excludeIdle: value })}
                  size="small"
                  checkedIcon={<CheckBoxIcon />}
                  unCheckedIcon={<CheckBoxOutlineBlankIcon />}
                >
                </Checkbox>
              </div>
            )
          }
        </>
      )}
      {reportType?.id === 'idleReport' && (
        <>
          <Input
            id="minutes"
            label={messages['reportsMainRT_idleMinutesFilter']}
            type="number"
            min={0}
            minMessage={`${messages['min']}: 0`}
            max={1000}
            pattern={'^[0-9]'}
            maxLength={5}
            onlyPositive={true}
            maxMessage={`${messages['max']}: 1000`}
            onChange={(value) => {
              setOtherFilters({ ...otherFilters, minute: value })
            }}
            value={otherFilters.minute}
            requiredMessage={messages['required_field']}
            placeholder={messages['reportsMainRT_enterValue']}
          />

          {// Pending to connect with the API
          }
          <div className="report-checkbox-margin-top-20">
            <Checkbox
              id={'id_productive_idling'}
              value={otherFilters.checkIdleProductive}
              label={messages['reportSetup_productiveIdleCheckbox']}
              onChange={(value) => setOtherFilters({ ...otherFilters, checkIdleProductive: value })}
              size="small"
              checkedIcon={<CheckBoxIcon />}
              unCheckedIcon={<CheckBoxOutlineBlankIcon />}
            >
            </Checkbox>
          </div>
        </>
      )}
      {reportType?.id === 'behaviorReport' && (
          <>
            <Select
                id="selectBehaviorReportType"
                label={messages["exportBehavior_reportType"]}
                value={optionsSelectReportType?.filter(reportType => reportType.id == otherFilters.reportType)[0]}
                onChange={(event) => {
                  setOtherFilters({ ...otherFilters, reportType: event?.id })
                }}
                className="react-select-landmark"
                options={optionsSelectReportType}
                textField={'label'}
                valueField={'id'}
                required
            />
            <div style={{padding: "12px 25px"}}>
              <div style={{marginTop: 4, textAlign: 'center'}}>
                <SeverityOptions
                    customStyle={{justifyContent: 'center'}}
                    label={messages['behavior_riskFactors']}
                    onChange={(newSeverity) => {
                      let severity = newSeverity;
                      if (newSeverity === otherFilters?.risk) {
                        severity = null;
                      }
                      setOtherFilters({ ...otherFilters, risk: severity })
                    }}
                    selectedButtons={[{ id: otherFilters?.risk }]}
                    all={true}
                    messages={messages}
                />
              </div>
            </div>
          </>
      )}
      {reportType?.id === 'eventReport' && (
        <Select
          id="eventFilter"
          label={messages['reportsMainRT_eventFilter']}
          onChange={(value) => {
            setOtherFilters({ ...otherFilters, event: value?.id })
          }}
          value={selectEventTypeOptions?.filter(data => data.id === otherFilters.event)[0]}
          options={selectEventTypeOptions}
          textField="label"
          valueField="id"
          placeholder={messages['reportsMainRT_eventReport']}
          isClearable
          required
          requiredMessage={messages["generatedReport_selectAReportOption"]}
          isOptionDisabled={(option) => {
            return !option || option.disabled
          }}
        />
      )}{reportType?.id === 'vehServiceItemsReport' && (
        <Select
            id="serviceItemFilter"
            label={messages['reportsMainRT_serviceItemFilter']}
            onChange={(value) => {
              setOtherFilters({ ...otherFilters, event: value?.id })
            }}
            value={serviceItems?.filter(data => data.id === otherFilters.event)[0]}
            options={serviceItems}
            textField="title"
            valueField="id"
            placeholder={messages['reportsMainRT_vehServiceItemsReport']}
            isClearable
            requiredMessage={messages["generatedReport_selectAReportOption"]}
            isOptionDisabled={(option) => {
              return !option || option.disabled
            }}
        />
    )}

      {reportType?.id === 'serviceItemsReport' && (
          <Select
              id="serviceItemsFilter"
              label={messages['reportsMainRT_serviceItemFilter']}
              onChange={(value) => {
                setOtherFilters({ ...otherFilters, event: value?.id })
              }}
              value={services?.filter(data => data.id === otherFilters.event)[0]}
              options={services}
              textField="name"
              valueField="id"
              placeholder={messages['reportsMainRT_vehServiceItemsReport']}
              isClearable
              requiredMessage={messages["generatedReport_selectAReportOption"]}
              isOptionDisabled={(option) => {
                return !option || option.disabled
              }}
          />
      )}

      {(reportType?.id === 'landmarkBasedReport' || reportType?.id === 'landmarkSummaryReport') && (
        <>
          <Select
            id="landmarkBaseFilter"
            label={messages['reportsMainRT_landmarkView']}
            onChange={(value) => {
              setOtherFilters({ ...otherFilters, landmarkBaseView: value?.id })
              if(landmarkTags?.length === 0) {
                getAllLandmarks();
              }
            }}
            value={landmarkViewOptions?.filter(data => data.id === otherFilters.landmarkBaseView)[0]}
            options={landmarkViewOptions}
            textField="label"
            valueField="id"
            isClearable
            required
            requiredMessage={messages["required_field"]}
            isOptionDisabled={(option) => {
              return !option || option.disabled
            }}
          />
          {otherFilters.landmarkBaseView == 'single' && (
            <Select
              label={messages["landmark"]}
              value={landmarks?.filter(landmark => landmark.id == otherFilters.landmark)[0]}
              onChange={(event) => {
                setOtherFilters({ ...otherFilters, landmark: event?.id })
              }}
              id="id"
              className="react-select-landmark"
              options={landmarks}
              textField={'name'}
              valueField={'id'}
              required
              disabled={landmarkTagsLoading}
            />
          )}

          {otherFilters.landmarkBaseView == 'tags' && (
            <Select
              label={messages["menu_tags"]}
              value={otherFilters?.landmarkTags}
              onChange={(event) => {
                setOtherFilters({ ...otherFilters, landmarkTags: event })
              }}
              id="id"
              className="react-select-landmark"
              options={landmarkTags}
              textField={'name'}
              valueField={'id'}
              required
              disabled={landmarkTagsLoading || filter}
            />
          )}
        </>
      )}

      {reportType?.id === 'geofenceBasedReport' && (
        <>
          <Select
            id="geofenceBaseFilter"
            label={messages['reportsMainRT_geofenceView']}
            onChange={(value) => {
              setOtherFilters({ ...otherFilters, geofenceBaseView: value?.id })
            }}
            value={geofenceViewOptions.filter(data => data.id === otherFilters.geofenceBaseView)[0]}
            options={geofenceViewOptions}
            textField="label"
            valueField="id"
            isClearable
            required
            requiredMessage={messages["required_field"]}
            isOptionDisabled={(option) => {
              return !option || option.disabled
            }}
          />
          {otherFilters.geofenceBaseView == 'single' && (
            <Select
              label={messages["geofence"]}
              value={geofences.filter(geofence => geofence.id == otherFilters.geofence)[0]}
              onChange={(event) => {
                setOtherFilters({ ...otherFilters, geofence: event?.id })
              }}
              id="id"
              className="react-select-geofence"
              options={geofences}
              textField={'name'}
              valueField={'id'}
              required
              disabled={geofenceTagsLoading || filter}
            />
          )}

          {otherFilters.geofenceBaseView == 'tags' && (
            <Select
              label={messages["menu_tags"]}
              value={otherFilters.geofenceTags}
              onChange={(event) => {
                setOtherFilters({ ...otherFilters, geofenceTags: event })
              }}
              id="id"
              className="react-select-landmark"
              options={getGeofencesTags()}
              textField={'name'}
              valueField={'id'}
              required
              disabled={geofenceTagsLoading || filter}
            />
          )}
        </>
      )}
      {reportType?.id === 'engineHoursReport' && (
        <div>
          <p>{messages['reportsMainRT_engineHoursReportCaution']}</p>
        </div>
      )}
      {['driverFormsReport','formStopSummaryReport'].includes(reportType?.id) && (
        <>
          <Select
            id="formFilter"
            label={messages['reportsMainRT_formFilter']}
            onChange={(value) => {
              if (value?.id === 'all'){
                const form = forms.filter(data => data.name === 'All Forms');
                setOtherFilters({ ...otherFilters, formView: value?.id, form: form[0]?.id })
              } else {
                setOtherFilters({ ...otherFilters, formView: value?.id, form: null })
              }
            }}
            value={driverViewOptions?.filter(data => data.id == otherFilters.formView)[0]}
            options={driverViewOptions}
            textField="label"
            valueField="id"
            isClearable
            required
            requiredMessage={messages["reportsMainRT_formReport"]}
            isOptionDisabled={(option) => {
            return !option || option.disabled
          }}
        />
        {otherFilters.formView == 'single' && (
            <Select
              label={messages["reportsMainRT_Form"]}
              value={forms?.filter(data => data.id == otherFilters.form)[0]}
              onChange={(value) => {
                setOtherFilters({ ...otherFilters, form: value?.id })
              }}
              id="id"
              className="react-select-geofence"
              options={forms.filter(data => data.name !== 'All Forms')}
              textField={'name'}
              valueField={'id'}
            />
          )}
          </>
        )}
      {['driverFormsReportV2', 'formStopSummaryReportV2'].includes(reportType?.id) && (
        <>
          <Select
            id="formFilter"
            label={messages['reportsMainRT_formFilter']}
            onChange={(value) => {
              if (value?.id === 'all'){
                setOtherFilters({ ...otherFilters, formView: value?.id, form: 1 })
              } else {
                setOtherFilters({ ...otherFilters, formView: value?.id, form: null })
              }
            }}
            value={driverViewOptions?.filter(data => data.id == otherFilters.formView)[0]}
            options={driverViewOptions}
            textField="label"
            valueField="id"
            isClearable
            required
            requiredMessage={messages["reportsMainRT_formReport"]}
            isOptionDisabled={(option) => {
            return !option || option.disabled
          }}
        />
        {otherFilters.formView == 'single' && (
            <Select
              label={messages["reportsMainRT_Form"]}
              value={dynamicForms?.filter(data => data.id == otherFilters.form)[0]}
              onChange={(value) => {
                setOtherFilters({ ...otherFilters, form: value?.id })
              }}
              id="id"
              className="react-select-geofence"
              options={dynamicForms}
              textField={'name'}
              valueField={'id'}
            />
          )}
          </>
        )}
        {reportType?.id === 'driverCoachingReport' && (
            <Select
                id="serviceItemsFilter"
                label={messages['reportsMainRT_eventType']}
                onChange={(value) => {
                  const useAllOption = advancedFilter ? [] : [{key: 'all'}];
                  const filters = value?.length ? (value[value.length - 1].key === 'all' ? useAllOption : value.filter(event => event.key !== 'all')) : useAllOption;
                  setOtherFilters({ ...otherFilters, eventTypes: filters })
                }}
                value={eventTypeVideoOptions?.filter(data => otherFilters.eventTypes?.map(event => event?.key).includes(data?.key))}
                options={eventTypeVideoOptions}
                textField="label"
                valueField="key"
                placeholder={messages['reportsMainRT_eventType']}
                isClearable
                multiple
                required
                requiredMessage={messages["generatedReport_selectAReportOption"]}
                isOptionDisabled={(option) => {
                  return !option || option.disabled
                }}
            />
        )}
      {reportType?.id === 'smartOneCUtilizationReport' && (
        <div>
          <p>{messages['reportsMainRT_smartOneCUtilizationReportCaution']}</p>
        </div>
      )}
    </div>
  );
}

export default ReportCustomFields;
