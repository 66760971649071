import { IconButton, Tooltip } from "@gpstrackit/gtc-react-controls";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GetFormatForMoment } from "Core/data/Helpers";
import { NotificationManager } from "Components/Notifications";
import { savePreference, searchAvailableClips } from "Redux/actions";

const PREFERENCE_KEY = "AvClipUpdated";
const MAX_MINUTES_ATTEMPS = 3;

export const RefreshAvClips = ({ timezone, id }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { isRefreshAvClips, unitsList } = useSelector(state => state.catalogsRedux);
  const { availableClips } = useSelector((state) => state.clipsRedux);
  const { preferencesUser, loadingPreference } = useSelector(
    (state) => state.preferencesRedux
  );
  const { user, authenticatedId } = useSelector((state) => state.securityRedux);
  const { dateformat } = user;
  const [lastUpdated, setLastUpdated] = useState("");
  const [canUpdate, setCanUpdate] = useState(true);

  const isCanUpdate = () => {
    if (loadingPreference) return;
    const getLastUpdatedByUser = preferencesUser?.find(
      (item) => item?.preferenceKey === PREFERENCE_KEY
    );
    if (!getLastUpdatedByUser) return setCanUpdate(true);
    const preference =
      JSON.parse(getLastUpdatedByUser?.preferenceValue) ?? null;
    if (!preference) return setCanUpdate(true);
    const isSameUnit = preference[id]
    if(!isSameUnit) return setCanUpdate(true);
    const getDiffDate = moment().diff(
      moment(isSameUnit),
      "minutes"
    );
    if (getDiffDate < MAX_MINUTES_ATTEMPS) return setCanUpdate(false);
    setCanUpdate(true);
  };

  useEffect(() => {
    if(!isRefreshAvClips) return
    const { esn, refresh } = isRefreshAvClips
    if(!refresh) return
    const filterEsn = unitsList?.find(unit => unit?.esn === esn)
    if(!filterEsn || !filterEsn?.id || filterEsn?.id != id) return
    dispatch(searchAvailableClips({ esn: id }))
    dispatch(clearRedux("CATALOG_REDUX", { isRefreshAvClips: null }));
  }, [isRefreshAvClips])

  useEffect(() => {
    isCanUpdate();
    const interval = setInterval(() => {
        isCanUpdate();
    }, 10000);
    return () => clearInterval(interval);
  }, [preferencesUser]);

  useEffect(() => {
    if (availableClips?.length) {
      const lastUpdatedDate = availableClips[0]?.requestedOn ?? availableClips[0]?.updatedOn
      if(!lastUpdatedDate) {
        const avClips = availableClips[0]?.validTimeRangeSeq;
        const getLastClip = avClips?.length
          ? JSON.parse(avClips[avClips?.length - 1])
          : null;
        let getLastDate = new Date(getLastClip[1]);
        if (!getLastDate) return;
        setLastUpdated(
          moment
            .tz(getLastDate, timezone)
            .format(`${GetFormatForMoment(dateformat)} hh:mm:ss a`)
        );
      } else {
        setLastUpdated(
          moment
            .tz(lastUpdatedDate, timezone)
            .format(`${GetFormatForMoment(dateformat)} hh:mm:ss a`)
        );
      }
      
    }
  }, [availableClips]);

  if (!lastUpdated || loadingPreference) return;

  const refreshAvClips = () => {
    if (!canUpdate) return;
    NotificationManager.warning(
      messages["videoClipRequest_warningUpdate"],
      "",
      3000,
      null,
      null,
      "warning",
      null
    );
    const lastAvClipUpdated = { [id]: moment() };
    setCanUpdate(false);
    dispatch(
      savePreference(
        "User",
        authenticatedId,
        "AvClipUpdated",
        lastAvClipUpdated
      )
    );
    dispatch(searchAvailableClips({ esn: id, preloaded: false }))
  };

  return (
    <Box mt="10px">
      <div
        style={{
          padding: "0px 10px 0 10px",
          background: "#fafab2",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "12px" }}>
          {messages["videoClipRequest_lastUpdated"]} {lastUpdated}
        </p>
        <Tooltip
          title={canUpdate ? "" : messages["videoClipRequest_updateInProgress"]}
        >
          <IconButton
            isDisabled={!canUpdate}
            icon="refresh"
            onClick={() => refreshAvClips()}
          />
        </Tooltip>
      </div>
    </Box>
  );
};
