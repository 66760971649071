export default {
  "infoBubble_location": "Location",
  "infoBubble_dtc": "DTC",
  "infoBubble_address": "Address",
  "infoBubble_currentTime": "Current Time",
  "infoBubble_unitTime": "Unit Time",
  "infoBubble_distance": "Distance",
  "infoBubble_status": "Status",
  "infoBubble_type": "Type",
  "infoBubble_speed": "Speed",
  "infoBubble_event": "Event",
  "infoBubble_latLng": "Lat/Long",
  "infoBubble_createLandmark": "Create Landmark",
  "infoBubble_driver": "Driver",
  "infoBubble_speedMessage": "* Speed may not be accurate due to weak signal.",
  "infoBubble_vehicleInfo": "Vehicle Info",
  "infoBubble_vehicleName": "Vehicle Name",
  "infoBubble_odometer": "Odometer",
  "infoBubble_engineHours": "Engine Hours",
  "infoBubble_yearMakeModel": "Year/Make/Model",
  "infoBubble_vin": "VIN",
  "infoBubble_licensePlate": "License Plate",
  "infoBubble_tempReading": "Temp Reading",
  "infoBubble_streetView": "Street View",
  "infoBubble_videocam": "Video",
  "infoBubble_snapshot": "Snapshot",
  "infoBubble_settings": "Settings",
  "infoBubble_live_snapshots": "Live Snapshots",
  "infoBubble_live": "Live",
  "infoBubble_requestVideo": "Request Video",
  "infoBubble_trail": "Trail",
  "infoBubble_map": "Map",
  "infoBubble_compare": "Compare",
  "infoBubble_getTrail": "Get Trail",
  "infoBubble_video": "Video",
  "infoBubble_streetViewNotAvailable": "Street view not available for this location.",
  "infoBubble_getMarkerDetailsError": "An error occurred, please try again.",
  "infoBubble_landmarks": "Landmarks",
  "infoBubble_waypointNumbers": "Waypoint Numbers",
  "infoBubble_otherVehicles": "Other Vehicles",
  "infoBubble_connectingLine": "Trail Line",
  "infoBubble_getSnapshotError": "An error occurred, please try again.",
  "infoBubble_noSnapshotAvailable": "No snapshot available.",
  "infoBubble_snapshotDateTime": "Time of snapshot: ",
  "infoBubble_Datetime": "Date/Time",
  "infoBubble_Date": "Date",
  "infoBubble_Time": "Time",
  "infoBubble_clipRange": "Clip Range",
  "infoBubble_seconds20": "20 sec clip (10 sec before, 10 sec after)",
  "infoBubble_seconds40": "40 sec clip (20 sec before, 20 sec after)",
  "infoBubble_seconds60": "60 sec clip (30 sec before, 30 sec after)",
  "infoBubble_requestClip": "Request",
  "infoBubble_openRequestClip": "Open Timeline",
  "infoBubble_chooseClip": "Choose Clip",
  "infoBubble_requestSuccess": "Your video has been requested. When it finishes downloading it will be available in the video tab.",
  "infoBubble_requestError": "An error occurred, please try again.",
  "infoBubble_done": "Done",
  "infoBubble_goToVideoTab": "Go to video tab",
  "infoBubble_createNewVideoClip": "Create new videoclip",
  "infoBubble_landmarkInfo": "Landmark Info",
  "infoBubble_geofenceInfo": "Geofence Info",
  "infoBubble_routePointInfo": "Route Point Info",
  "infoBubble_routePointInfo_history": "Status Change Log",
  "infoBubble_requestRangeTimeError": "There is not data available in the selected time range",
  "infoBubble_defaultGroup": "Default Group",
  "infoBubble_media": "Media",
  "infoBubble_cameraNotFound": "Camera not found",
  "infoBubble_crewMembers": "Crew Members",
  "infoBubble_clip": "Event Clip",
  "infoBubble_pointInfo": "Point Info",
  "infoBubble_radius": "Radius",
  "infoBubble_tags": "Tags",
  "infoBubble_allTags": "All Tags",
  "infoBubble_videoClip_from": "From",
  "infoBubble_videoClip_to": "To",
  "infoBubble_notes": "Notes",
  "infoBubble_validDate": "A valid date must be selected",
  "infoBubble_noEventsClip": "There are no events for the selected unit",
  "infoBubble_clipRequestError": "An error occurred try again later",
  "infoBubble_clipRequestSuccess": "Your video has been requested. When it finishes downloading, it will be available in the video tab",
  "infoBubble_camera_sdcard_status": "Camera SD Card status is",
  "infoBubble_warnings": "Warnings",
  "infoBubble_sdCardError": "SD Card Error",
  "infoBubble_deviceInfo": "Device Info",

  "infoBubble_pairedCamera": "Paired Camera",
  "infoBubble_lastMessageTime": "Last Message Time",
  "infoBubble_signalStrength": "GPS Signal Strength",
  "infoBubble_cameraSignalStrength": "Camera Signal Strength",
  "infoBubble_GPSDeviceType": "GPS Device Type",
  "infoBubble_cameraDeviceType": "Camera Device Type",

  "infoBubble_hdop_ideal": "Ideal",
  "infoBubble_hdop_excellent": "Excellent",
  "infoBubble_hdop_good": "Good",
  "infoBubble_hdop_moderate": "Moderate",
  "infoBubble_hdop_fair": "Fair",
  "infoBubble_hdop_poor": "Poor",
  "infoBubble_hdop_unknown": "Unknown",
  "infoBubble_hdop_no_signal": "No Signal",

}