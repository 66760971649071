import contactsRedux from "Modules/account/views/contacts/redux/reducers";
import loginHistoryRedux from "Modules/account/views/loginHistory/redux/reducers";
import legacyApiKeyRedux from "Modules/account/views/legacyApiKey/redux/reducers";
import subUserRedux from "Modules/account/views/subUser/redux/reducers";
import userChangePasswordRedux from "Modules/account/.components/changePassword/redux/reducer";
import apiKeysRedux from "Modules/account/views/apiKeys/redux/reducer";
import tagsRedux from "Modules/account/views/tags/redux/reducer";
import createEditTagRedux from "Modules/account/views/tags/components/createEditTag/redux/reducer";
import viewEntitiesTagRedux from "Modules/account/views/tags/components/viewEntities/redux/reducer";
import mergeTagsRedux from "Modules/account/views/tags/components/mergeTags/redux/reducer";
import roleRedux from "Modules/account/views/role/redux/reducers";
import moduleRedux from "Modules/account/views/role/components/modules/redux/reducers";
import deleteTagsRedux from "Modules/account/views/tags/components/deleteTags/redux/reducer";
import accountWebHooksRedux from "Modules/account/views/webHooks/redux/slice";
import accountApikeysRedux from "../../views/apiKeys/redux/slice";

export default {
  contactsRedux,
  loginHistoryRedux,
  legacyApiKeyRedux,
  subUserRedux,
  userChangePasswordRedux,
  apiKeysRedux,
  tagsRedux,
  createEditTagRedux,
  viewEntitiesTagRedux,
  mergeTagsRedux,
  roleRedux,
  moduleRedux,
  deleteTagsRedux,
  accountWebHooksRedux,
  accountApikeysRedux
};