import { createSelector } from "reselect";

import { useSelector, useDispatch } from "react-redux";
import {
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback,
} from "react";
import { download } from "Core/data/Helpers";
import {
  ACTION_ENUM,
  DEVICEMODEL_ENUM,
  MAX_ATTEMPTS,
  MODE_ENUM,
  ROTATE_ENUM,
  STATUS_ENUM,
  THUMBNAILURL_SMARTWITNESS,
  TYPE_ENUM,
} from "./constants";
import _ from "lodash";
import { endpoints } from "Core/defaultValues";
// Actions
import {
  startStreaming,
  startLivePhoto,
  stopStreaming,
  getStreamingStatus,
  getLivePhotoStatus,
  getClipById,
  getPictureById,
  getStreamingStatusResponse,
  startClipStreaming,
  stopClipStreaming,
  getClipStreamingStatus,
  clearRedux,
} from "Redux/actions";
import { useIntl } from "react-intl";

let VIDFLEET_VIDEO_RETRY_TIMER = null;

const getStatus = (state) => state.vidFleetVideoRedux.cameraStatusResponse;
const getStatusLoading = (state) =>
  state.vidFleetVideoRedux.loadingCameraStatus;

const cameraOnlineStatusServer = (id) => {
  return createSelector([getStatus, getStatusLoading], (status, loading) => {
    return [loading[id], status[id]];
  });
};

const getPicture = (state) => state.vidFleetVideoRedux.pictureResponse;
const getPictureLoading = (state) => state.vidFleetVideoRedux.loadingPicture;

const pictureServer = (pictureId) => {
  return createSelector(
    [getPicture, getPictureLoading],
    (pictures, loading) => {
      return [loading[pictureId], pictures[pictureId]];
    }
  );
};

const getVideo = (state) => state.vidFleetVideoRedux.response;
const getVideoLoading = (state) => state.vidFleetVideoRedux.loading;

const videoServer = (id) => {
  return createSelector([getVideo, getVideoLoading], (video, loading) => {
    return [loading[id], video[id]];
  });
};

export const useVidFleetVideo = (ref, props) => {
  const dispatch = useDispatch();
  const videoPlayerRef = useRef();
  const { messages } = useIntl();
  const {
    id,
    type,
    unitId,
    mode,
    autoplay,
    deviceModel,
    dewrapImage,
    thumbnailUrl,
    pictureId,
    disabled,
    simProviderName,
    vehicleEvent,
    timeZone,
    name,
    dateRange,
    disableDownload = false,
    onClickPlay,
  } = props;

  const [url, setUrl] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showLoading, setShowLoading] = useState(null);
  const [message, setMessage] = useState("");
  const [openImg, setOpenImg] = useState(false);
  const [counterAttempts, setCounterAttemps] = useState(0);
  const [loadingCameraStatus, cameraStatus] = useSelector(
    cameraOnlineStatusServer(id),
    (prevProps, nextProps) => {
      return _.isEqual(prevProps, nextProps);
    }
  );

  const [loadingPicture, pictureResponse] = useSelector(
    pictureServer(pictureId),
    (prevProps, nextProps) => {
      return thumbnail || _.isEqual(prevProps, nextProps);
    }
  );

  const loadings = useSelector((state) => state.vidFleetVideoRedux.loading);

  const [loading, response] = useSelector(
    videoServer(id),
    (prevProps, nextProps) => {
      return url || _.isEqual(prevProps, nextProps);
    }
  );

  useImperativeHandle(ref, () => ({
    downloadVideo: downloadVideo,
    downloadPicture: downloadPicture,
    startStopLiveStreaming: startStopLiveStreaming,
  }));

  //stop startStopLiveStreaming when type is
  // streaming, photo, clipStreaming initial state
  useEffect(() => {
    return () => {
      if (
        type === TYPE_ENUM.streaming ||
        type === TYPE_ENUM.photo ||
        type === TYPE_ENUM.clipStreaming
      ) {
        startStopLiveStreaming("stop");
      }
      dispatch(clearRedux("VIDFLEET_VIDEO"));
    };
  }, []);

  useEffect(() => {
    if (id && mode === MODE_ENUM.video) {
      if (
        (autoplay && type === TYPE_ENUM.streaming) ||
        type === TYPE_ENUM.photo ||
        type === TYPE_ENUM.clipStreaming
      ) {
        if (
          vehicleEvent &&
          (simProviderName === SIM_PROVIDER_NAME_ENUM.usc ||
            simProviderName === SIM_PROVIDER_NAME_ENUM.usCellular) &&
          cameraStatus
        ) {
          if (
            cameraStatus?.lastConnectTime < cameraStatus?.lastDisconnectTime
          ) {
            setMessage(
              messages
                ? messages["vidFleet_unableToLiveStream"]
                : "The vehicle is currently offline - unable to live stream"
            );
            setShowPlayButton(false);
          } else {
            startStopLiveStreaming("start");
          }
        } else {
          startStopLiveStreaming("start");
        }
      } else if (type === TYPE_ENUM.playback) {
        if (props?.url && props?.thumbnailUrl) {
          setMessage("");
          setUrl(props.url);
          setThumbnail(props.thumbnailUrl);

          if (autoplay) {
            setPlayingVideo(true);
            setShowPlayButton(false);
          } else {
            setShowPlayButton(true);
          }

          setShowLoading(false);
        } else {
          dispatch(getClipById(id));
        }
      }
    } else if (mode === MODE_ENUM.picture) {
      setShowPlayButton(false);
    }
  }, [
    id,
    autoplay,
    type,
    mode,
    simProviderName,
    cameraStatus,
    vehicleEvent,
    dateRange,
  ]);

  useEffect(() => {
    if (!thumbnail && thumbnailUrl) {
      if (props?.thumbnailUrl?.includes(THUMBNAILURL_SMARTWITNESS))
        setThumbnail(`${endpoints.SMARTWITNESS_API_URL}${props?.thumbnailUrl}`);
      else setThumbnail(thumbnailUrl);
    } else if (!thumbnail && pictureId) {
      dispatch(getPictureById(pictureId));
    }
  }, [thumbnailUrl, pictureId]);

  useEffect(() => {
    if (!thumbnail && pictureResponse) {
      if (pictureResponse.error) {
        setThumbnail(null);
      } else {
        setThumbnail(pictureResponse.picUrl);
      }
    }
  }, [pictureResponse]);

  useEffect(() => {
    if (response) {
      setShowPlayButton(false);
      setPlayingVideo(false);
      setShowLoading(true);
      clearTimeout(VIDFLEET_VIDEO_RETRY_TIMER);

      //Handle an error with the request to VidFleet API
      if (response?.error) {
        setMessage(
          messages
            ? messages[response?.message || "vidFleet_errorApi"]
            : "Error"
        );
        setShowPlayButton(true);
        setPlayingVideo(false);
        setShowLoading(false);
      } else {
        //The video is a streaming|live son has multiples states
        if (
          type === TYPE_ENUM.streaming ||
          type === TYPE_ENUM.photo ||
          type === TYPE_ENUM.clipStreaming
        ) {
          //The camera is in live streaming
          if (response?.status === STATUS_ENUM.live) {
            clearTimeout(VIDFLEET_VIDEO_RETRY_TIMER);

            if (type === TYPE_ENUM.streaming || type === TYPE_ENUM.photo) {
              setMessage("");
              setUrl(
                type === TYPE_ENUM.photo ? response?.url : response?.playUrl
              );
              setPlayingVideo(true);
              setShowPlayButton(false);
              setShowLoading(false);

              if (type === TYPE_ENUM.photo) {
                VIDFLEET_VIDEO_RETRY_TIMER = setTimeout(() => {
                  setUrl(null);
                  setShowLoading(false);
                  setShowPlayButton(true);
                  setPlayingVideo(false);
                  setMessage("");
                }, 40000);
              }
            } else if (type === TYPE_ENUM.clipStreaming) {
              setMessage("");
              if (url != response?.playUrl) {
                setUrl(response?.playUrl);
              }

              setPlayingVideo(true);
              setShowPlayButton(false);
              setShowLoading(false);
              VIDFLEET_VIDEO_RETRY_TIMER = setTimeout(() => {
                dispatch(
                  getClipStreamingStatus({
                    esn: id,
                    unitId,
                    transactionId: response?.transactionId,
                  })
                );
              }, 4000);
            }
          } else if (response?.status === STATUS_ENUM.mp4Uploaded) {
            clearTimeout(VIDFLEET_VIDEO_RETRY_TIMER);
            setMessage("");
            if (url != response?.clip?.mp4Url) {
              setUrl(response?.clip?.mp4Url);
            }

            setPlayingVideo(true);
            setShowPlayButton(false);
            setShowLoading(false);
          } else if (response?.status === STATUS_ENUM.uploading) {
            setMessage(messages ? messages["vidFleet_uploading"] : "Loading");
            setPlayingVideo(false);
            if (type === TYPE_ENUM.photo) {
              VIDFLEET_VIDEO_RETRY_TIMER = setTimeout(() => {
                setUrl(null);
                setShowLoading(false);
                setShowPlayButton(true);
                setPlayingVideo(false);
                setMessage("");
              }, 61000);
            }
          }
          //We need to wait until the camera wake up
          else if (
            response?.status === STATUS_ENUM.waitForPublish ||
            response?.status === STATUS_ENUM.waitForAwake
          ) {
            if (MAX_ATTEMPTS === counterAttempts) {
              setMessage(
                messages ? messages["vidFleet_cameraOffline"] : "Loading"
              );
              setShowLoading(false);
              setPlayingVideo(false);
              setShowPlayButton(true);
              setOpenImg(false);
              setCounterAttemps(0);
              return;
            }

            setCounterAttemps((previousVal) => previousVal + 1);

            setMessage(
              messages ? messages["vidFleet_wakingCamera"] : "Loading"
            );
            setPlayingVideo(false);

            //setOpenImg(false);
            VIDFLEET_VIDEO_RETRY_TIMER = setTimeout(() => {
              if (type === TYPE_ENUM.photo) {
                dispatch(
                  getLivePhotoStatus({
                    esn: id,
                    unitId,
                    transactionId: response?.transactionId,
                  })
                );
              } else if (type === TYPE_ENUM.clipStreaming) {
                dispatch(
                  getClipStreamingStatus({
                    esn: id,
                    unitId,
                    transactionId: response?.transactionId,
                  })
                );
              } else {
                dispatch(getStreamingStatus({ esn: id, unitId }));
              }
            }, 4000);
          }
          //We need to wait until the camera stops to stream
          else if (response?.status === STATUS_ENUM.waitForStop) {
            setMessage(
              messages ? messages["vidFleet_stopingCamera"] : "Loading"
            );
            setUrl(null);
            setPlayingVideo(false);
            VIDFLEET_VIDEO_RETRY_TIMER = setTimeout(() => {
              if (type === TYPE_ENUM.clipStreaming) {
                dispatch(
                  getClipStreamingStatus({
                    esn: id,
                    unitId,
                    transactionId: response?.transactionId,
                  })
                );
              } else {
                dispatch(getStreamingStatus({ esn: id, unitId }));
              }
            }, 4000);
          }
          //The streaming is off
          else if (
            response?.status === STATUS_ENUM.stopped ||
            response?.status === STATUS_ENUM.timeout
          ) {
            setUrl(null);
            setShowLoading(false);
            setShowPlayButton(true);
            setPlayingVideo(false);
            setMessage(messages["vidFleet_cameraOffline"]);
            setOpenImg(false);
          }
          //The camera is offline
          else if (response?.status === STATUS_ENUM.offline) {
            setMessage(
              messages ? messages["vidFleet_cameraOffline"] : "Loading"
            );
            setShowLoading(false);
            setPlayingVideo(false);
            setShowPlayButton(true);
            setOpenImg(false);
          } else if (response?.status === STATUS_ENUM.firmwareNotSupport) {
            setMessage(
              messages ? messages["vidFleet_firmwareNotSupport"] : "Loading"
            );
            setShowLoading(false);
            setPlayingVideo(false);
            setShowPlayButton(true);
            setOpenImg(false);
          }
          //There is not clip available in the selected time range
          else if (response?.status === STATUS_ENUM.failed) {
            setMessage(messages ? messages["vidFleet_failed"] : "Loading");
            setShowLoading(false);
            setPlayingVideo(false);
            setShowPlayButton(true);
            setOpenImg(false);
          }
        }
        //The video playback has not states
        else if (type === TYPE_ENUM.playback) {
          setMessage("");
          setUrl(response?.mp4Url);
          setThumbnail(response?.thumbnailUrl);

          if (autoplay) {
            setPlayingVideo(true);
            setShowPlayButton(false);
          } else {
            setShowPlayButton(true);
          }

          setShowLoading(false);
        }
      }
    }
  }, [response, autoplay]);

  const startStopLiveStreaming = useCallback((_action, _dateRange) => {
    clearTimeout(VIDFLEET_VIDEO_RETRY_TIMER);
    setCounterAttemps(0)
    if (_action === ACTION_ENUM.start) {
      if (type === TYPE_ENUM.photo) {
        dispatch(startLivePhoto({ esn: id, unitId }));
      } else if (type === TYPE_ENUM.clipStreaming) {
        const dr = dateRange || _dateRange;
        if (dr?.start && dr?.end) {
          dispatch(startClipStreaming({ esn: id, unitId, dateRange: dr }));
        }
      } else {
        dispatch(startStreaming({ esn: id, unitId }));
      }
    } else if (_action === ACTION_ENUM.stop) {
      if (type === TYPE_ENUM.photo) {
      } else if (type === TYPE_ENUM.clipStreaming) {
        dispatch(stopClipStreaming({ esn: id, unitId }));
      } else {
        dispatch(stopStreaming({ esn: id, unitId }));
      }
    }
  });

  let isUpsidedown = false;
  if (
    response?.rotate === ROTATE_ENUM.upsidedown ||
    pictureResponse?.rotate === ROTATE_ENUM.upsidedown
  ) {
    isUpsidedown = true;
  }

  let options = {
    autoplay: true,
    controls: true,
    url: url,
    is360: deviceModel === DEVICEMODEL_ENUM.device360 ? true : false,
    downloadable: disableDownload
      ? false
      : type === TYPE_ENUM.streaming
      ? false
      : true,
    videoType: type,
    notice360Message: messages ? messages["vidFleet_360Message"] : "Error",
    errorMessage: messages ? messages["vidFleet_retryMessage"] : "Error",
    timeoutMessage: messages ? messages["vidFleet_timeout"] : "Error",
    finishedMessage: messages ? messages["vidFleet_stoppedCamera"] : "Error",
    id: id,
    bigPlayButton: false,
    name: name || id,
  };

  if (type === TYPE_ENUM.streaming) {
    options.stopCallback = () => {
      dispatch(stopStreaming({ esn: id, unitId }));
      if (videoPlayerRef) videoPlayerRef?.current?.clearVideo();
    };
    options.retryCallback = () => {
      setUrl(null);
      setPlayingVideo(false);
      if (videoPlayerRef) videoPlayerRef?.current?.clearVideo();
      let res = {};
      res[`${id}`] = null;
      dispatch(getStreamingStatusResponse(res));
      dispatch(startStreaming({ esn: id, simProviderName, unitId }));
    };
    options.reloadCallback = () => {
      dispatch(getStreamingStatus({ esn: id, unitId }));
    };
  }

  const setLoading = (newLoading) => {
    let ld = { ...loadings };
    ld[id] = newLoading || false;
    dispatch(clearRedux("VIDFLEET_VIDEO", { loading: ld }));
  };

  //functions
  const downloadVideo = useCallback(() => {
    if (url) {
      setLoading(true);
      download(url, `${name || id}.mp4`, setLoading);
    }
  });

  const downloadPicture = async () => {
    if (thumbnail) {
      try {
        await fetch(thumbnail)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${name || id}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      } catch (e) {
        return window.open(thumbnail, "_blank");
      }
    }
  };

  return {
    disabled,
    deviceModel,
    dewrapImage,
    thumbnail,
    id,
    pictureId,
    isUpsidedown,
    videoPlayerRef,
    options,
    response,
    timeZone,
    thumbnailUrl,
    onClickPlay,
    startStopLiveStreaming,
    setPlayingVideo,
    setShowPlayButton,
    setOpenImg,
    playingVideo,
    showPlayButton,
    showLoading,
    message,
    openImg,
    loadingCameraStatus,
    loadingPicture,
    loading,
    mode,
    type,
    url,
  };
};
