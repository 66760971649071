import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import _, { isNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  reportsMainGenerateReportProgress,
  reportShowMessage,
  reportUpdateField,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import { put } from 'redux-saga/effects';

import { getDateTimeZoneUser } from 'Core/data/Helpers';

import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import {
  getTimeZone as getTimeZoneUser
} from 'Modules/reports/views/main/components/reportsTab/helper';

// Actions
import {
  createAsyncReport
} from 'Redux/actions';
import { useIntl } from 'react-intl';


export const changeDate = (chosenRange, timeframe) => {
  switch (chosenRange) {
    case 'Today':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().startOf('day'),
        endDate: moment.max(moment())
      };

    case 'Yesterday':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().subtract(1, 'days').startOf('day'),
        endDate: moment.max(moment())
      };

    case 'This Week':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().day(0).startOf('day'),
        endDate: moment.max(moment())
      };

    case 'Last Week':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().day(-7).startOf('day'),
        endDate: moment().day(-1).endOf('day')
      };

    case 'This Month':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().startOf('month'),
        endDate: moment.max(moment())
      };

    case 'Last Month':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment(moment()).subtract(1, 'months').startOf('month'),
        endDate: moment(moment()).subtract(1, 'months').endOf('month')
      };

    case 'This Quarter':
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().startOf('quarter'),
        endDate: moment.max(moment())
      };

    case 'Last Quarter':
      const quarter = moment().quarter();
      return {
        ...timeframe,
        range: chosenRange,
        startDate: moment().quarter(quarter - 1).startOf('quarter'),
        endDate: moment().quarter(quarter - 1).endOf('quarter'),
      };

    case 'Custom':
      return {
        ...timeframe,
        range: chosenRange,
        endDate: moment(timeframe.endDate).endOf('day')
      }

    default:
      return {
        ...timeframe,
      };
  }
}

export const setReportTypeOption = (type) => {
  switch (type.name) {
    case 'Detailed Report':
      return 'Detailed'
    case 'Idle Report':
      return 'Idle'
    default:
      return null
  }
}

export const mapAddress = (latitude, longitude) => {
  return {
    latitude: latitude,
    longitude: longitude,
    direction: 35,
    heading: '1',
    Label: 'temporal'
  }
}


export const formatDatetimeNoTZ = (timestamp, selectedFormat) => {
  if(!timestamp){
    return '';
  }
  const lan = localStorage.getItem('lan')
  return moment(timestamp.substr(0, 19)).locale(lan).format(selectedFormat);
}

export const formatDatetime = (timestamp, selectedFormat, timeZone) => {
  if(!timestamp){
    return '';
  }
  const lan = localStorage.getItem('lan')
  const format =  moment(timestamp).locale(lan).tz(getTimeZoneUser(timeZone)).format(selectedFormat)
  if(format === "Invalid date"){
    return moment(timestamp, "MM-DD-YYYY HH:mm:ss A").format(selectedFormat)
  }
  return format;
}

export const formatTimeDelta = (timedelta, messages) => {
  let minutes = Math.floor(timedelta / 60);
  const seconds = timedelta % 60;
  const daysMessage = `${(messages && messages['daysTime']) || `days`}`
  const secondsMessage = `${(messages && messages['sec']) || `sec`}`
  if (minutes > 60) {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    if (hours > 24){
      const days = Math.floor(hours / 24);
      hours = hours % 24;
      return `${days || 0} ${daysMessage}, ${hours || 0} hr, ${minutes || 0} min, ${Math.round(seconds)} ${secondsMessage}`;
    }
    return `${hours || 0} hr, ${minutes || 0} min, ${Math.round(seconds)} ${secondsMessage}`;
  }

  return `${minutes || 0} min, ${Math.round(seconds || 0)} ${secondsMessage}`;
}


/**
 *  receives the time in seconds and converts it to hours
 * @param {number} timeInSeconds
 * @returns
 */
export const formatTimeHours = (timeInSeconds) =>{
  return Number(timeInSeconds / 3600).toFixed(2);
}

/**
 * This method receives the duration format chosen by the user and returns a method for its formatting.
 * @param {string} formatDuration
 */
export const getMethodToFormatDuration = (formatDuration) => {
  const methodToFormat = {
    "hh": formatTimeHours,
    "DDhhmmss": formatTimeDelta
  }
  return methodToFormat[formatDuration] || formatTimeDelta;
}

export const formatGeneralNumber = (value, places = 2) => {
  const output = Number(value || 0);
  return Number.parseFloat(output).toFixed(places);
  // return new Intl.NumberFormat(intlCode, { style: 'decimal' }).format(value)
}

export const truncateAddress = (address, charLimit = 13) => {
  return address.slice(0, charLimit + 1) + '...';
}

export const unitOrDriverLabel = entity => {
  const {messages} = useIntl()
  return entity === 'Unit' ? messages['generatedReport_driver'] : messages['generatedReport_unit'];
}

export const postedSpeedColor = (speed, speedLimit) => {
  if ((!_.isNumber(speed) && !speed) || (!speedLimit || speedLimit == 0) || (Math.round(speed) === 0)) {
    return '#000000'
  } else if (+speed <= +speedLimit) return 'green';
  else if (+speed > +speedLimit && speed <= (+speedLimit + 10)) return 'orange'
  else return 'red'
}

export const handleSignalIcon = (value) => {
  const valueToString = value? String(value): 0;
  switch (valueToString) {
    case '0':
      return <SignalCellular0BarIcon style={{fontSize: 21.602}}/>
    case '1':
      return <SignalCellular1BarIcon style={{fontSize: 21.602}}/>
    case '2':
      return <SignalCellular2BarIcon style={{fontSize: 21.602}}/>
    case '3':
      return <SignalCellular3BarIcon style={{fontSize: 21.602}}/>
    case '4':
      return <SignalCellular3BarIcon style={{fontSize: 21.602}}/>
    case '5':
      return <SignalCellular4BarIcon style={{fontSize: 21.602}}/>
    default:
      return <SignalCellular0BarIcon style={{fontSize: 21.602}}/>
  }
}

export const formatTabLabel = (type, index) => {
  switch (type) {
    case 'detailedReport':
      return 'Detailed ' + String(index).padStart(2, "0")
    case 'movingReport':
      return 'Moving ' + String(index).padStart(2, "0")
    case 'actReport':
      return 'Activity w/ Hour Meter ' + String(index).padStart(2, "0")
    case 'driverFormsReportV2':
      return 'Driver Form ' + String(index).padStart(2, "0")
    case 'driverStatusReportV2':
      return 'Driver Status ' + String(index).padStart(2, "0")
    case 'hourOfUseReport':
      return 'Hour Of Use ' + String(index).padStart(2, "0")
    case 'idleReport':
      return 'Idle ' + String(index).padStart(2, "0")
    case 'ignitionReport':
      return 'Ignition ' + String(index).padStart(2, "0")
    case 'mileageReport':
      return 'Mileage ' + String(index).padStart(2, "0")
    case 'speedReport':
      return 'Speed ' + String(index).padStart(2, "0")
    case 'speedCoachingReport':
      return "Speed Coaching " + String(index).padStart(2, "0")
    case 'stopReport':
      return 'Stop ' + String(index).padStart(2, "0")
    case 'startStopIdleReport':
      return 'Start, Stop, Idle ' + String(index).padStart(2, "0")
    case 'simpleReport':
      return 'Simple ' + String(index).padStart(2, "0")
    case 'summaryReport':
      return 'summary ' + String(index).padStart(2, "0")
    case 'advancedSummaryReport':
      return 'Advanced Summary ' + String(index).padStart(2, "0")
    case 'mileageSummaryReport':
      return 'Mileage Summary ' + String(index).padStart(2, "0")
    case 'driverMileageSummaryReport':
      return 'Driver Mileage Summary ' + String(index).padStart(2, "0")
    case 'landmarkReport':
      return 'Landmark ' + String(index).padStart(2, "0")
    case 'driverSafetyReport':
      return 'Driver Safety ' + String(index).padStart(2, "0")
    case 'alertLogReport':
      return 'Alert Log ' + String(index).padStart(2, "0")
    case 'stateMileageReport':
      return 'State Mileage ' + String(index).padStart(2, "0")
    case 'chatHistoryReportV2':
      return 'Chat History ' + String(index).padStart(2, "0")
    case 'behaviorReport':
      return 'Behavior ' + String(index).padStart(2, "0")
    case 'formStopSummaryReportV2':
      return 'Form Stop Summary ' + String(index).padStart(2, "0")
    case 'stateMileageSummariesReport':
      return 'State Mileage Summaries ' + String(index).padStart(2, "0")
    case 'eventReport':
      return 'Event ' + String(index).padStart(2, "0")
    case 'pingReport':
      return 'Ping ' + String(index).padStart(2, "0")
    case 'odometerReport':
      return 'Odometer ' + String(index).padStart(2, "0")
    case 'tripReport':
      return 'Daily Trip ' + String(index).padStart(2, "0")
    default:
      return type
  }
}

export const getNameEntity = (filterEntity, messages) => {
  return filterEntity === 'Driver' ? messages['generatedReport_driver'] : messages['generatedReport_unit'];
}

export const getNameVehicle = (filterEntity, messages) => {
  return filterEntity === 'Driver' ? messages['generatedReport_driver'] : messages['generatedReport_vehicle'];
}

export const getGroupField = (filterEntity) => {
  return filterEntity === 'Driver' ? 'driverId' : 'deviceId';
}

export const getItemName = (filterEntity, item) => {
  return filterEntity === 'Driver' ? item.driverName : item.deviceLabel;
}

export const getCategoryFilterType = (filterType) => {
  return filterType === 'Driver' ? 'driverId' : 'deviceId';
}

export const getTimeZone = (userTimeZone) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimeZone || tz;
}

export const decimalHandler = (number, messageNotAvailable) => {
  const newNumber = Number.parseFloat(number)
  return number && isNumber(newNumber) && !isNaN(newNumber) ? newNumber.toFixed(2) : (messageNotAvailable || 0)
}

export const getEntityType = (report, messages) => {
  return report?.filters?.filterType === "deviceId" || report?.filters?.filterType === "unitGroupId" ? messages['generatedReport_unit'] :
  messages['generatedReport_driver'];
}

export const getEntitiesType = (report, messages) => {
  return report?.filters?.filterType === "deviceId" || report?.filters?.filterType === "unitGroupId" ? messages['generatedReport_units'] :
  messages['generatedReport_drivers'];
}

export const getEntitiesAllType = (filterType, messages) => {
  return filterType === "deviceId" || filterType === "unitGroupId" ? messages['reportSetup_allUnit'] :
  messages['reportSetup_allDriver'];
}

export const getPlaceholderSelector = (entity, messages) => {
  const placeholder = {
    unitGroupId: messages['generatedReport_unit_group'],
    deviceId: messages['generatedReport_unit'],
    driverGroupId: messages['generatedReport_driver_group'],
    driverId: messages['generatedReport_driver'],
  }
  let entityType = placeholder[entity];
  return `${messages['reportsView_filterBy']} ${entityType}`
}


export const getNameUnitDriverForSubtables =  (filterEntity, messages) =>{
  return filterEntity === 'Unit' ? messages['generatedReport_driver'] : messages['generatedReport_unit'];
}

export const getNameUnitDriverTag =  (filterEntity, messages, notUpperCase = false) =>{
  const options= {
    'unit': messages['generatedReport_unit'],
    'driver': messages['generatedReport_driver'],
    'tag': messages['fleetView_group'],
  }
  try {
    const title = options[filterEntity?.toLowerCase()] || filterEntity;
    return notUpperCase ? title : title.toUpperCase();
  } catch(e) {
    return filterEntity;
  }
}

export const getFilterNameByEntity = () => {
  return 'label';
}

export const  isJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const getCategoryFilter = (option) => {
  const options = {
    "UnitId": "UnitId",
    "driverId": "driverId",
    "deviceId": "deviceId",
    "unitGroupId": "deviceId",
    "driverGroupId": "driverId"
  }
  return options[option] || '';
}

export const FIELD_NAME_IS_STARRED = "starredOn";
export const FIELD_NAME_REPORT = "reportName";
export const TYPE_REPORT_RECENT = "recents";
export const TYPE_REPORT_STARRED = "starred";
export const useHandleExportReport = (report) => {
  const dispatch = useDispatch()
  const { user: {durationFormat} } = useSelector(state => state.securityRedux);
  const lang = useSelector(state => state.commomRedux.language);
  const reportsTypes = {
    "PDF": "pdf",
    "CSV": "csv"
  }
  const handleExport = (type) => {
    report.lang = lang;
    report.export = true;
    report.exportFromTable = true;
    report.durationFormat = durationFormat;
    report.delayed = null;
    report.exportType = reportsTypes[type] || type
    dispatch(reportsMainGenerateReportUpdate(report, 1, 10))
  }

  const pinReport = () => {
    dispatch(reportUpdateField(report.userId, new Date().toISOString(), FIELD_NAME_IS_STARRED, report.createdOn))
  }

  return {
    generateExport: handleExport,
    pinReport,
    serverSideTable: true
  }
}

export function* handleAsyncReport(report, data, accumulateData ){
  if(report.isAll){
    report.filters.filterArray = report.allEntities;
  }
  report.export = false;

  if(data?.Report?.reportInstanceId){
      // If report is async
      yield put(reportShowMessage(true, "SUCCESS", "reportsMainRT_exportReportMessageStarted", "reportsMainRT_exportReportTitleStarted"))
      // create async report in the local reports array
      report.loading = false;
      yield put(createAsyncReport(report, data?.Report?.reportInstanceId))
      if(report.exportType === "json" || report.exportFromTable) {
        report.exportFromTable = false;
        yield put(reportsMainGenerateReportProgress(report))
      }

  }else {
    report.loading = false
    report.asyncLoading = false
    report.exportFromTable = false;
    if(data?.Report?.items){
      let items = JSON.parse(JSON.stringify(data.Report))
      items.items = convertTimeItems(items.items, 'unitTime', report.timeZone);
      // reports with infinite scroll should accumulate the results of the reports
      report.data = accumulateData ? {
        ...report.data,
        items: [
          ...(report.data?.items || []),
          ...items.items
        ],
        total: data?.Report?.total || report.data.total || 0
      }: items;
    } else {
      yield put(reportsMainGenerateReportProgress(report))
      yield put(reportShowMessage(true, "ERROR", "reportsMainRT_noDataMessage", "reportsMainRT_exportReportTitleFailed"));
    }
    yield put(reportsMainGenerateReportProgress(report))
  }

    // When a group does not have ids, we show a error

    // report.loadSync = true;
    // loading for the table
    // report.asyncLoading = false;

  // yield handleResultReport(report, data)
}
export function* handleResultReport(report, data, timeZone){
  //if (!report.export) {
    if (data?.Report?.items) {
      let items = JSON.parse(JSON.stringify(data.Report))
      items.items = convertTimeItems(items.items, 'unitTime', timeZone);
      report.data = items
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
}

export const convertTimeItems = (items, fileToConvert, timeZone) => {
  // giving an array of items return the items with the time converted to the user time zone
  if(items?.length && timeZone){
    const rows = items.map((item) => {
      const unitTime = item[fileToConvert];
      if(unitTime){
        item[fileToConvert] = getDateTimeZoneUser(unitTime, timeZone)
      }
      return item;
    })
    return rows;
  }
  return items;
}

export const converDateToTimeZone = (date, timeZone) => {
  const newDate = typeof date === 'string' ? date.slice(0, -1) : date;
  const resp = momentTz.tz(newDate, timeZone).format();
  return resp;
}

export const converDateToUTC = (date) => {
  const dateToUtc = moment(date).utc().format();
  return dateToUtc;
}
export const formatDateFromFilter = (date, timeZone) => {
  const dateToUtc = moment(date).utc().format();
  const resp = momentTz(dateToUtc).tz(timeZone);
  return resp;
}

export const convertUTCtimeToLocalTimeZone = (utcTime) => {
  // timeZone
  // convert date to string
  const timeToString = moment(utcTime).utc().format("YYYY-MM-DD h:mm:ss a");
  // convert timezone to UTC
  const newDate = momentTz(timeToString).utc().format();
  return newDate;
}

const getDriversAddedDriverGroupId = (driversTable, drivers) => {
  const driversWithGroupId = driversTable.map((driverTable) => {
    const id = driverTable.Id || driverTable.id || driverTable.driverId;
    const driver = drivers.find(driver => driver.driverId === id);
    return {
      ...driverTable,
      groupId: driver?.groupId
    }
  });
  return driversWithGroupId
}
export const getDriversFromDriverGroupSelected = (driversTable, selectedDriversGroups, drivers) => {
  const driversWithGroupId =  drivers ? getDriversAddedDriverGroupId(driversTable, drivers) : driversTable;
  const driversInGroup = driversWithGroupId.filter(driver => {
    if(driver.groupId) {
      let isInTheGroup = false;
      selectedDriversGroups.map(selectedItem => {
        if(selectedItem.id === driver.groupId) {
          isInTheGroup = true;
        }
      })
      return isInTheGroup;
    }
  })
  return driversInGroup;
}

export const getUnitsFromDriverGroupSelected = (units, currentGroupsSelected, currentUnitsTable, unitGroupField, unitSingleField) => {
  let unitsByGroup = {}
  currentGroupsSelected.map(group => {
    units.map(unit => {
      if(unit.unitGroups?.length){
        unit.unitGroups?.map(unitGroup => {
          if(unitGroup.unitgroup_id == group.id) {
            unitsByGroup[unit.id] = unitsByGroup[unit.id] ? [...unitsByGroup[unit.id], group.id] : [group.id];
          }
        })
      }
    })
  });
  let groupedItems = currentUnitsTable.filter(row => {
    const id = row.Id || row.id || row.deviceId;
    if (unitsByGroup[id]) {
      return true;
    }
  })
  return groupedItems;
}

export const generateReportInstance = (isExport, dataResultPath, exportFromTable) => {
  // validation to know when to generate the reportInstanceId: If is mode export and does not have dataResultPath
  return ((isExport  && !dataResultPath) || exportFromTable);
}

export const baseReportConditions = ({
  report,
  name,
  dataResultPath,
  timeZone,
  delayed,
  unitsSelected,
  timeframeName,
  filterType,
  filterArray,
  startRange,
  endRange,
  categoryName,
  dates
}) => {
  // delete events and summary to reduce size UIParams
  const newReport = JSON.parse(JSON.stringify(report))
  delete newReport.events
  delete newReport.summary
  // -99 means ALL
  const hasAll = (unitsSelected?.findIndex((item) => item?.id == "-99")) >= 0;

  let rowsToQuery = unitsSelected?.length && !hasAll ? unitsSelected.map(x => ({id: x.id, type: x.name || x.type || x['__typename'], parentId: x.parentTagId})) : filterArray.map(x => ({id: x.id, type: x.name || x.type || x['__typename'], parentId: x.parentTagId}));

  if(dates?.length > 0 && dates[0]?.id != 99) {
    dates.map((date) => {
      rowsToQuery.push({
        "id": date?.id,
        "type": "StartDate"
      })
    });
  }

  return {
    reportName: name,
    excludeEmptyTables: report.excludeEmptyTables,
    UIParams: JSON.stringify(newReport),
    delayed: delayed,
    dataResultPath,
    timeZone: getTimeZone(timeZone),
    action: report.exportFromTable? "export" : dataResultPath ? "getData" : "getDataAsync",
    category: {
      field: filterType,
      categoryName: categoryName,
      dataResultFilter: (!hasAll && !!unitsSelected?.length) || (dates?.length && dates[0]?.id != 99),
      entities: rowsToQuery,
      includeInactiveDrivers: report.filters.includeInactiveDrivers
    },
    dateRange: {
      fields: [
        "unitTime"
      ],
      GTE: startRange,
      LTE: endRange,
      timeframeName: timeframeName,
      reportTimeRanges: report.reportTimeRanges
    },
    sessionId: sessionStorage.getItem("sessionId")
  };
}

const masterUserHasPermission = (user, reportOption) => {
  let userReportOptions =  user?.reportOptions?.split(',') || []
  return userReportOptions.includes(reportOption);
}

export const disableReportInSelect = (user, permissions, reportOption) => {
  if(masterUserHasPermission(user, reportOption)){
    if(user.isSubUser){
      let result = true;
      let subuserOptions = user && user.options && user.options.split(',') || []
      if (Array.isArray(permissions)){
        permissions.forEach(p => {
          if (subuserOptions.includes(p.toString()))
            result = false;
        })
      } else {
        result = !subuserOptions.includes(permissions)
      }
      return result
    }
    return false;
  }
  return true;

}

export const isJsonFormat = (exportType) =>{
  return !!(exportType === "json");
}

export const isKPH = (kph) =>{
  return kph ? 'generatedReport_km' : 'generatedReport_miles';
}
export const isKPHLower = (kph) =>{
  return kph ? 'generatedReport_kmLower' : 'generatedReport_milesLower';
}

export const isKPHAcronym = (kph) =>{
  return kph ? 'generatedReport_kmAcronym' : 'generatedReport_miAcronym';
}

export const isKPHAcronymSpeed = (kph) =>{
  return kph ? 'kph' : 'mph';
}

export const getColorSpeed = (speed) => {
  if (speed <= 25)
  {
    return 'report-speed-text-info';
  }
  else if (speed > 25 && speed <= 55)
  {
    return 'report-speed-text-danger-lt';
  }
  else if (speed > 55 && speed <= 85)
  {
    return 'report-speed-text-danger';
  }
  else if (speed > 85 && speed < 200)
  {
    return 'report-speed-text-warning';
  }
  else
  {
    return '';
  }
}


export const getGroupId = (item) => {
  return `${item.recordInfo?.entityId} - ${item.recordInfo?.tagId}`;
}
